body {
  background-color: #7e2cda;
  height: initial;
}

.brand-wrapper {
  margin-bottom: 0px;
  border-radius: 50%;
  position: relative;
  padding: 20px;
  background: #7e2cda;

  .logo {
    height: 50px;
  }
}

.login-card {
  border: 0;
  border-radius: 27.5px;
  box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);
  overflow: hidden;

  &-img {
    border-radius: 0;
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .card-body {
    justify-content: center;
    align-items: center;
    display: flex;
    justify-items: center;
    flex-direction: column;
    padding: 25px 20px 20px;
    min-height: 500px;

    @media (max-width: 767px) {
      min-height: 0;
    }
    @media (max-width: 422px) {
      padding: 25px 20px;
    }
  }

  &-description {
    font-size: 25px;
    color: #000;
    font-weight: normal;
    margin-bottom: 23px;
  }

  form {
    max-width: 400px;
    width: 100%;
    @media (max-width: 767px) {
      max-width: 100%;
    }
  }

  .input-group-prepend {
    display: flex;
  }

  .form-control {
    border: 1px solid #d5dae2;
    padding: 15px 25px;
    min-height: 45px;
    font-size: 13px;
    font-weight: normal;
  }

  .input-group-text {
    background: transparent;
    .material-icons {
      color: #7e2cda;
    }
  }

  .login-btn {
    padding: 13px 20px 12px;
    background-color: #7e2cda;
    border-radius: 4px;
    font-size: 17px;
    font-weight: bold;
    line-height: 20px;
    color: #fff;
    margin-bottom: 24px;

    &:hover {
      border: 1px solid #7e2cda;
      background-color: transparent;
      color: #7e2cda;
    }
  }

  .forgot-password-link {
    font-size: 14px;
    color: #919aa3;
    margin-bottom: 5px;
  }

  .register-link {
    font-size: 14px;
    color: #7e2cda;
    font-weight: bold;
    margin-bottom: 5px;
    text-decoration: none;
  }

  &-footer-text {
    font-size: 16px;
    color: #000;
  }

  &-footer-nav {
    a {
      font-size: 14px;
      color: #919aa3;
    }
  }
}

.auth-page {
  height: 100vh;
}
@media (max-width: 767px) {
  .auth-page {
    // height: 85vh;
  }
}
