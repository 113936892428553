$material-icons-font-path: '~material-icons/iconfont/';

@import '~material-icons/iconfont/material-icons.scss';
@import 'auth';

.container {
  max-width: 1000px;
}

.application {
  min-height: 100vh;
}

.main {
  min-height: 60vh;
  border-radius: 25px;
  padding: 25px;
  box-shadow: 0 10px 30px 0 rgba(172, 168, 168, 0.43);
}

.card-state {
  .lead {
    font-size: 1.8rem;
    font-weight: 300;
    line-height: 2rem;
  }

  .card-text {
    opacity: 0.6;
    font-size: 0.875rem;
  }

  .icon {
    font-size: 50px;
    color: #7e2cda;
  }
}

.profile-nav {
  .list-group-item.active {
    background-color: #7e2cda;
    border-color: #7e2cda;
  }
}

.card-action {
  cursor: pointer;
  &:hover {
    opacity: 0.8;
    border-color: #7e2cda;
  }
}

.modal-title {
  width: 100%;
}

.UserAvatar {
  display: inline-block;
  color: white;
}

.app-logo {
  display: flex;
}
